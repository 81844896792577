require('./bootstrap');

import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';

Alpine.plugin(mask);

window.Alpine = Alpine;

Alpine.start();

import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

window.Chart = Chart;

import ApexCharts from 'apexcharts';

window.ApexCharts = ApexCharts;

window.TomSelect = require('tom-select');

//import flatpickr from "flatpickr";

window.moment = require('moment');
